export const a_fields = {
  /* 權限 */
  manage_teacher: { prop: "manage_teacher", component: "switch" },
  manage_projects: { prop: "manage_projects", component: "switch" },
  manage_news: { prop: "manage_news", component: "switch" },
  manage_fields: { prop: "manage_fields", component: "switch" },
  set_form: { prop: "set_form", component: "switch" },
  set_score: { prop: "set_score", component: "switch" },
  set_score_teacher: { prop: "set_score_teacher", component: "switch" },
  get_score_status: { prop: "get_score_status", component: "switch" },
  set_awards: { prop: "set_awards", component: "switch" },
  manage_review: { prop: "manage_review", component: "switch" },
  manage_event_pics: { prop: "manage_event_pics", component: "switch" },
  manage_classes: { prop: "manage_classes", component: "switch" },
  view_signup_status: { prop: "view_signup_status", component: "switch" },
};

export const a_names = {
  manage_teacher: "老師帳號管理",
  manage_projects: "專題資訊管理",
  manage_classes: "班級管理",
  manage_news: "最新資訊管理",
  manage_fields: "領域管理",
  manage_review: "評審帳號管理",
  set_form: "設定報名表",
  set_score: "設定評分表",
  set_score_teacher: "設定評分老師",
  get_score_status: "取得評分結果",
  manage_event_pics: "活動花絮管理",
  view_signup_status: "檢視報名狀況",
  set_awards: "成績管理",
};
