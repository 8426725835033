/**
 * Array分組
 * @param {*} fn callback function
 * @returns {key:Array}
 */
Array.prototype.groupBy = function (fn) {
  const arr = this;
  let group = {};
  for (let i = 0, len = arr.length; i < len; i++) {
    const item = arr[i],
      key = fn(item);
    group[key] = group[key] || [];
    group[key].push(item);
  }
  return group;
};
/**
 * Array依照key轉成map
 * @param {*} fn callback function
 * @returns {key:value}
 */
Array.prototype.mapBy = function (fn) {
  const arr = this;
  let map = {};
  for (let i = 0, len = arr.length; i < len; i++) {
    const item = arr[i];
    // console.log("item", item);
    map[fn(item)] = item;
  }
  return map;
};

/**
 * 將陣列轉化為map，並對每個值進行處理
 * @param {*} fn 依照甚麼key進行分類的函數
 * @param {*} resolve 對每個陣列進行處理的函數
 * @returns
 */
Array.prototype.mapByResolve = function (fn, resolve) {
  const arr = this;
  let map = {};
  for (let i = 0, len = arr.length; i < len; i++) {
    const key = fn(arr[i]);
    map[key] = resolve(arr[i]);
  }
  return map;
};

Array.prototype.subtracting = function (arr) {
  return this.filter((v) => !arr.includes(v));
};
