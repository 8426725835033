/**
 * 防抖
 * @param {Function} fn
 * @param {object} config
 * @param {Number} config.delay 延遲多久執行
 * @param {Boolean} config.immediately 立即執行
 * @returns
 */
export function debounce(fn, config = { delay: 1000, immediately: true }) {
  const { delay = 1000, immediately = true } = config;
  let timer = null;
  //點擊第一次執行
  if (immediately) {
    return function () {
      const firstClick = !timer;
      if (timer) clearTimeout(timer);

      if (firstClick) fn.apply(this, arguments);
      timer = setTimeout(() => {
        // console.log("timer setTimeout", timer);
        timer = null;
      }, delay);
    };
  }
  // 最後一次執行
  return function () {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
}
/**
 * 節流
 * @param {Function} fn
 * @param {Int} delay
 * @returns A function that it's first argument is the event
 */
export function throttle(fn, delay = 1000) {
  let begin = 0;
  return function () {
    const curTime = Date.now();
    // console.log("curtime", curTime - begin);
    if (curTime - begin > delay) {
      fn.apply(this, arguments);
      begin = curTime;
    }
  };
}
