import { post } from "../axiosConfig";

const type = 4;
export const getSignList = () => post(`${type}/get_sign_list`);

/**
 * 取得報名內容
 * @param {{team_id:number}} data
 * @returns
 */
export const getTeamDetail = (data) => post(`${type}/get_team_details`, data);
/**
 * 簽核
 * @param {{confirm:Boolean, team_id:Number, signature?:string}} data
 * @returns
 */
export const postSign = (data) => post(`${type}/sign`, data);
/**
 * 評分領域選填 - 取得領域
 * @returns
 */
export const getScoreFields = () => post(`${type}/get_fields`);
/**
 * 評分領域選填 - 選填領域
 * @param {{id:Number[]}} data
 * @returns
 */
export const postChooseFields = (data) => post(`${type}/choose_fields`, data);
/**
 * 線上評分 - 取得所有評分組別
 * @param {{role:Number}} data
 * @returns
 */
export const getAllScoreTeams = ({ role }) => post(`${role}/get_all_team`);
/**
 * 線上評分 - 取得組別資訊
 * @param {{data:{team_id:Number}, role:Number}} data
 * @returns
 */
export const getScoreTeamDetail = ({ data, role }) =>
  post(`${role}/get_team_info`, data);
/**
 * 取得評分表
 * @param {{role:Number}} data
 * @returns
 */
export const getScoreForm = ({ role }) => post(`${role}/get_score`);
/**
 * 暫存評分
 * @param {{data:{team_id:Number, score:Number}, role:Number}} data
 * @returns
 */
export const postScore = ({ data, role }) => post(`${role}/score`, data);
/**
 * 送出評分
 * @param {{data:FormData, role:Number}} data
 * @returns
 */
export const postSubmitScore = ({ data, role }) =>
  post(`${role}/send_score`, data);
/**
 * 取得所有組別
 * @param {{project_id:Number}} data
 * @returns
 */
export const getInstructingTeams = (data) => post(`${type}/get_teams`, data);
