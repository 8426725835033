import { ROUTE_NAMES } from "@/router/RouteNames";

/* header */
const header = () => import("@/components/Container/HeaderContainer");
export const scoreRoute = {
  path: "scores",
  meta: {
    svg: "user-pen",
  },
  components: {
    header,
    default: () => import("@/views/bcms/teacher/ScoreDetails"),
  },
  redirect: { name: ROUTE_NAMES.bcms.teacher.scoreDetails.index },
  children: [
    {
      name: ROUTE_NAMES.bcms.teacher.scoreDetails.index,
      path: "",
      component: () =>
        import("@/views/bcms/teacher/ScoreDetails/subpages/list.vue"),
    },
    {
      name: ROUTE_NAMES.bcms.teacher.scoreDetails.id,
      path: ":id",
      component: () =>
        import("@/views/bcms/teacher/ScoreDetails/subpages/detail.vue"),
    },
  ],
};
export const teacher_routes = {
  teacher: [
    {
      path: "sign",
      meta: {
        svg: "file-signature",
      },
      redirect: { name: ROUTE_NAMES.bcms.teacher.registrationSign.index },
      components: {
        header,
        default: () => import("@/views/bcms/teacher/RegistrationSign"),
      },
      children: [
        {
          name: ROUTE_NAMES.bcms.teacher.registrationSign.index,
          path: "",
          component: () =>
            import("@/views/bcms/teacher/RegistrationSign/subpages/list.vue"),
        },
        {
          name: ROUTE_NAMES.bcms.teacher.registrationSign.id,
          path: ":id",
          component: () =>
            import("@/views/bcms/teacher/RegistrationSign/subpages/detail.vue"),
        },
      ],
    },
    {
      path: "fields",
      name: ROUTE_NAMES.bcms.teacher.scoreFields,
      meta: {
        svg: "user-tag",
      },
      components: {
        header,
        default: () => import("@/views/bcms/teacher/ScoreFields"),
      },
    },
    scoreRoute,
    {
      path: "teams",
      meta: {
        svg: "people-group",
      },
      components: {
        header,
        default: () => import("@/views/bcms/teacher/InstructingTeams"),
      },
      redirect: { name: ROUTE_NAMES.bcms.teacher.teams.index },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.bcms.teacher.teams.index,
          component: () =>
            import("@/views/bcms/teacher/InstructingTeams/subpages/list.vue"),
        },
        {
          path: ":id",
          name: ROUTE_NAMES.bcms.teacher.teams.id,
          component: () =>
            import("@/views/bcms/teacher/InstructingTeams/subpages/detail.vue"),
        },
      ],
    },
  ],
  // /* 簽核報名表 */
  // sign: [
  //   {
  //     path: "/registration/sign",
  //     name: "registration-sign",
  //     meta: {
  //       title: "簽核報名表 - 資訊與流通學院專題成果展",
  //       header_title: "簽核報名表",
  //     },
  //     components: {
  //       header,
  //       default: () => import("@/views/bcms/teacher/RegistrationSign"),
  //     },
  //   },
  // ],

  // /* 領域選填 */
  // choose_fields: [
  //   {
  //     path: "/score/fields",
  //     name: "score-fields",
  //     meta: {
  //       title: "領域選填 - 資訊與流通學院專題成果展",
  //       header_title: "領域選填",
  //     },
  //     components: {
  //       header,
  //       default: () => import("@/views/bcms/teacher/ScoreFields"),
  //     },
  //   },
  // ],
  // /* 評分 */
  // score: [
  //   {
  //     path: "/score",
  //     name: "score",
  //     meta: {
  //       title: "評分 - 資訊與流通學院專題成果展",
  //       header_title: "評分",
  //     },
  //     components: {
  //       header,
  //       default: () => import("@/views/bcms/teacher/ScoreDetails"),
  //     },
  //   },
  // ],

  // get_team_result: [
  //   /* 指導組別名單 */
  //   {
  //     path: "/instructing/teams",
  //     name: "instructing-teams",
  //     meta: {
  //       title: "指導組別名單 - 資訊與流通學院專題成果展",
  //       header_title: "指導組別名單",
  //     },
  //     components: {
  //       header,
  //       default: () => import("@/views/bcms/teacher/InstructingTeams"),
  //     },
  //   },
  //   /* 檢視小組得分 */
  //   {
  //     path: "/instructing/scores",
  //     name: "instructing-scores",
  //     meta: {
  //       title: "檢視小組得分 - 資訊與流通學院專題成果展",
  //       header_title: "檢視小組得分",
  //     },
  //     components: {
  //       header,
  //       default: () => import("@/views/bcms/teacher/InstructingScores"),
  //     },
  //   },
  // ],
};
