import { createRouter, createWebHistory } from "vue-router";

import { not_found_route } from "./modules/404_route";
import { ROUTE_NAMES, ROUTE_TITLES } from "./RouteNames";

const yearParam = ":year([^teacher]+)";
const basicRoutes = [
  /**
   * 報名
   */
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/Signup"),
  },
  {
    path: "/signup/success",
    name: "signup-success",
    meta: {
      title: "報名成功 - 資訊與流通學院專題成果展",
    },
    component: () => import("@/views/Signup/subpages/SignupSuccess"),
  },
  /* 登入 */
  {
    path: "/teacher/login",
    alias: ["/review/login", "/student/login"],
    component: () => import("@/views/bcms/Login"),
    redirect: { name: ROUTE_NAMES.login },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.login,
        component: () => import("@/views/bcms/Login/subpages"),
      },
      /* 忘記密碼 */
      {
        path: "/forgetPassword",
        name: ROUTE_NAMES.forgetPassword,
        component: () => import("@/views/bcms/Login/subpages/ForgetPassword"),
      },
    ],
  },
  {
    path: "/",
    redirect: { name: ROUTE_NAMES.client.home },
    component: () => import("@/views/client"),
    children: [
      /**
       * 前台首頁
       */
      {
        path: "",
        name: ROUTE_NAMES.client.home,
        component: () => import("@/views/client/Home"),
      },
      {
        path: `${yearParam}/news`,
        name: ROUTE_NAMES.client.news,
        component: () => import("@/views/client/News"),
      },
      {
        path: "procedures",
        name: ROUTE_NAMES.client.procedures,
        component: () => import("@/views/client/Procedures"),
      },
      {
        path: `${yearParam}/awards`,
        name: ROUTE_NAMES.client.awards,
        component: () => import("@/views/client/Awards"),
      },
      {
        path: `${yearParam}/achievements`,
        name: ROUTE_NAMES.client.achievements,
        component: () => import("@/views/client/Achievements"),
      },
      {
        path: `${yearParam}/achievements/:no`,
        name: ROUTE_NAMES.client.achievementsDetail,
        component: () => import("@/views/client/AchievementsDetail"),
      },
      {
        path: `${yearParam}/highlights`,
        name: ROUTE_NAMES.client.highlights,
        component: () => import("@/views/client/Highlights"),
      },
    ],
  },
  /* 404 */
  not_found_route,
];

const basicRouteNames = basicRoutes.reduce((acc, route) => {
  route.name && acc.push(route.name);
  if (route.children?.length)
    acc.push(...route.children.map((item) => item.name));
  return acc;
}, []);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: basicRoutes,
});

router.beforeEach((to) => {
  /* 設定title */
  const titleName = ROUTE_TITLES[to.name];
  if (to.meta.title) document.title = to.meta.title;
  else if (titleName) {
    document.title = `${titleName} - 資訊與流通學院專題成果展`;
  }

  if (!to.meta.header_title) to.meta.header_title = titleName;
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;

/**
 * 重新初始化路由
 */
export const reinitializeRouter = async () => {
  const _routes = router.getRoutes();
  // console.log("_routes :>> ", _routes);
  _routes.forEach((route) => {
    if (router.hasRoute(route.name) && !basicRouteNames.includes(route.name)) {
      // console.log("remove", route.name);
      router.removeRoute(route.name);
    }
  });
  // console.log("routes :>> ", router.getRoutes());
};
