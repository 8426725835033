import { ROUTE_NAMES, ROUTE_TITLES } from "@/router/RouteNames";

const header = () => import("@/components/Container/HeaderContainer");
export const student_routes = [
  {
    path: "registrations",
    name: ROUTE_NAMES.student.registration,
    meta: {
      title: "報名表資訊 - 資訊與流通學院專題成果展",
      header_title: ROUTE_TITLES[ROUTE_NAMES.student.information],
      svg: "info2",
    },
    components: {
      header,
      default: () => import("@/views/bcms/student/RegistrationInfo.vue"),
    },
  },
  {
    path: "information",
    name: "student-information",
    meta: {
      title: "專題資料 - 資訊與流通學院專題成果展",
      header_title: "專題資料",
      svg: "file-lines",
    },
    components: {
      header,
      default: () => import("@/views/bcms/student/ProjectInfo.vue"),
    },
  },
];
