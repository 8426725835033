import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueLazyLoad from "vue3-lazyload";
import SvgItem from "@/components/Global/SvgItem";
import Loading from "@/components/Global/Loading";
import installElementPlus from "./plugins/element";
import { store } from "./store";
const app = createApp(App);
import { debounce, throttle } from "./Debounce&Throttle";
import "./assets/css/app.scss";
installElementPlus(app);

app
  .component("SvgItem", SvgItem)
  .component("Loading", Loading)
  .use(store)
  .use(router)
  .use(VueLazyLoad, {
    lifecycle: {
      loading: () => {
        // console.log("loading", el);
      },
      error: () => {
        // console.log("error", el);
      },
      loaded: () => {
        // console.log("loaded", el);
      },
    },
  })
  .directive("debounce", {
    beforeMount(el, binding) {
      const { fn, delay = 1000 } = binding.value;
      // console.log("beforeMount", fn, params);
      // const proxy = function (...args) {
      //   return fn.apply(this, params.concat(args));
      // };
      // console.log(el, binding);
      el.$handle = debounce(fn, { delay });
      el.onclick = el.$handle;
      el.addEventListener("click", el.$handle);
    },
    unmounted(el) {
      el.removeEventListener("click", el.$handle);
    },
  })
  .directive("throttle", {
    beforeMount(el, binding) {
      const { fn, delay = 1000 } = binding.value;
      // console.log("beforeMount", fn, params);
      // const proxy = function (...args) {
      //   return fn.apply(this, params.concat(args));
      // };
      // console.log(el, binding);
      el.$handle = throttle(fn, delay);
      el.onclick = el.$handle;
      el.addEventListener("click", el.$handle);
    },
    unmounted(el) {
      el.removeEventListener("click", el.$handle);
    },
  })
  .mount("#app");
