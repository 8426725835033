import { project_routes } from "./modules/bcms/project_routes";
import { review_routes } from "./modules/bcms/review_routes";
import { setting_routes } from "./modules/bcms/setting_routes";
import { teacher_routes } from "./modules/bcms/teacher_routes";

export const routes = {
  ...project_routes,
  ...setting_routes,
  ...teacher_routes,
  ...review_routes,
};
