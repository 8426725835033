import { post } from "../axiosConfig";

/**匯入報名表欄位
 * @param {column_id=[]} data
 */
export const postImportColumns = (data) => post("3/import_column", data);

//年度專題
export const postProjectSettingAction = (data) =>
  post("3/manage_projects", data);

//匯入領域
export const postImportFields = (data) => post("3/import_field", data);
export const postSendFieldsNotification = () => post("3/send_fields_wishes");
export const getFieldWishes = (data) => post("3/get_fields_wishes", data);
//取得報名狀況
export const getRegistrationDetail = (data) =>
  post("3/get_signup_status", data);
export const getRegistrationDetailFile = (data) =>
  post("3/get_signup_status_file", data);
//取得各組評分老師
export const getScoreTeachersOfTeams = (data) =>
  post("3/get_score_teacher", data);
//取得所有老師評分的組別
export const getScoreTeamsOfTeachers = (data) =>
  post("3/get_teacher_score", data);

//取得所有評分老師
export const getScoreTeachers = (data) => post("3/get_teacher_wishes", data);
//調整評分老師
export const postModifyScoreTeacher = (data) =>
  post("3/set_score_teacher", data);
//發送評分通知
export const postSendScoreNotification = () => post("3/send_score");
//自動分配評分老師
export const postDistributeTeachers = (data) => post("3/auto_grouping", data);

//管理評審
export const getReviewsInfo = (data) => post("3/get_reviews", data);
export const postManageReview = (data) => post("3/manage_review", data);
//取得各組評審
export const getReviewsOfTeams = (data) => post("3/get_team_review", data);
// 取得評審名單(含數量)
export const getReviewList = (data) => post("3/get_review", data);
export const postManageReviewsOfTeams = (data) => post("3/set_review", data);

//活動花絮
export const getHighlights = (data) => post("3/get_event_pics", data);
export const postManageHighlights = (data) => post("3/manage_event_pics", data);

/**
 * 退回學生
 * @param {{team_id}} data
 * @returns
 */
export const postSendBackStudent = (data) => post("3/return", data);
/**
 * 設定編號
 * @param {{team_id, no}} data
 * @returns
 */
export const postSetNo = (data) => post("3/set_no", data);
export const postSendNewPasswordMail = (team_id) =>
  post("3/send_newpw", { team_id });
/**
 * 取得評分狀況
 * @param {{project_id:Number, field_id:Number}} data
 */
export const getScoreResults = (data) => post("3/get_score_status", data);

/**
 * 取得評分狀況(檔案)
 * @param {{project_id:Number}} data
 */
export const postExportExcel = (data) => post("3/get_score_status_file", data);

/**
 * 取得所有成績
 * @param {{project_id:Number, field_id:Number?, education_id:Number?}} data
 * @returns
 */
export const getGrades = (data) => post("3/get_final_grade", data);
/**
 * 上傳得獎名單
 * @param {{FILE:FILE}} data
 * @returns
 */
export const postUploadAwards = (data) => post("3/upload_awards", data);

/**
 * 公布成績及得獎名單//呼叫前提示 : 公布後無法取消公布
 * @returns
 */
export const postAnnounceAwards = () => post("3/announce");
