import {
  apiGetList,
  apiGetProjectNews,
  apiGetProjectInfo,
  apiPostAction,
  apiPostForgetPassword,
  apiPostLogin,
  apiPostResetPassword,
  apiPostSendFPEmail,
  apiPostTest,
  apiPostUploadEditorImg,
  apiGetProjectHighlights,
  apiGetFields,
  apiGetAchievements,
} from "@/api";
import router, { reinitializeRouter } from "@/router";
import useElPlus from "@/compositions/ElPlus.js";
import { isNullOrEmpty } from "@/compositions/logic";
/**
 * @type {import("vuex").StoreOptions}
 */
export default {
  namespaced: true,
  state: {
    api_list: {
      Executives: {
        type: 2,
        name: "行政帳號",
        fun: "Executives/setExecutives",
        getActionName: "get_account",
        postActionName: "manage_account",
      },
      Departments: {
        type: 2,
        name: "系所",
        fun: "Departments/setDepartments",
        getActionName: "get_departments",
        postActionName: "manage_departments",
      },
      Authorities: {
        type: 2,
        name: "權限",
        fun: "Authorities/setAuthorities",
        getActionName: "get_authorities",
        postActionName: "edit_authorities",
      },
      // authority_roles: {
      //   type: 2,
      //   fun: "authorities/setAuthorityRoles",
      //   getActionName: "get_authorities",
      // },
      Teachers: {
        1: {
          type: 2,
          name: "老師帳號",
          fun: "Teachers/setTeachers",
          getActionName: "get_all_teachers",
          postActionName: "manage_all_teacher",
        },
        2: {
          type: 3,
          name: "老師帳號",
          fun: "Teachers/setTeachers",
          getActionName: "get_teachers",
          postActionName: "manage_teacher",
        },
        3: {
          type: 3,
          name: "老師帳號",
          fun: "Teachers/setTeachers",
          getActionName: "get_teachers",
          postActionName: "manage_teacher",
        },
      },
      Logs: {
        type: 2,
        fun: "Logs/setLogs",
        getActionName: "get_activity_record",
      },
      Classes: {
        type: 3,
        name: "班級",
        fun: "Classes/setClasses",
        getActionName: "get_classes",
        postActionName: "manage_classes",
      },

      /* project */
      ProjectYears: {
        type: 1,
        name: "專題年份",
        fun: "global/setProjectYears",
        getActionName: "get_years",
      },
      Educations: {
        type: 6,
        fun: "global/setEducations",
        getActionName: "get_educations",
      },
      /**
       * @example RS = RegistrationSetting
       */
      RSEducations: {
        type: 6,
        fun: "RegistrationSettings/setData",
        getActionName: "get_educations",
      },
      RSDepartments: {
        type: 6,
        fun: "RegistrationSettings/setData",
        getActionName: "get_departments",
      },
      RSTeachers: {
        type: 6,
        fun: "RegistrationSettings/setData",
        getActionName: "get_teachers",
      },
      RSClasses: {
        type: 6,
        fun: "RegistrationSettings/setClasses",
        getActionName: "get_classes",
      },
      RSFields: {
        type: 6,
        fun: "RegistrationSettings/setFields",
        getActionName: "get_fields",
      },
      RSFormColumns: {
        type: 3,
        name: "報名表欄位",
        fun: "RegistrationSettings/CC/setFormColumns",
        getActionName: "get_form",
        postActionName: "set_form",
      },
      RSAuthorization: {
        type: 3,
        name: "授權同意書",
        fun: "RegistrationSettings/A/setAuthorizations",
        getActionName: "get_authorize",
        postActionName: "set_authorize",
      },
      RSRemark: {
        type: 3,
        name: "報名表備註",
        fun: "RegistrationSettings/R/setRemark",
        getActionName: "get_remark",
        postActionName: "set_remark",
      },
      //專題設定
      ProjectSetting: {
        type: 3,
        fun: "ProjectSettings/setProjectSetting",
        getActionName: "get_projects",
        postActionName: "manage_projects",
      },
      //最新消息
      News: {
        type: 3,
        name: "最新消息",
        fun: "News/setNews",
        getActionName: "get_news",
        postActionName: "manage_news",
      },
      //領域
      Fields: {
        type: 3,
        name: "領域",
        fun: "Fields/setFields",
        getActionName: "get_fields",
        postActionName: "manage_fields",
      },
      //評分表設定
      ScoreSettings: {
        type: 3,
        name: "評分表設定",
        fun: "ScoreSettings/setScoreSetting",
        getActionName: "get_score",
        postActionName: "set_score",
      },
    },
  },
  mutations: {},
  actions: {
    /* 登入 */
    login: async ({ dispatch, rootState }, data) => {
      const { code, data: { authorities, name, verify } = {} } =
        await apiPostLogin(data);
      if (code === 200) {
        //取出role_id並從authorities刪除
        const role_id = authorities["role_id"];
        delete authorities.role_id;
        try {
          //更新用戶資料
          await dispatch(
            "updateUserStatus",
            { authorities, user: name, verify, role_id },
            { root: true }
          );
          return rootState.menu[0].path;
        } catch (error) {
          //
        }
      }
    },
    /* 登出 */
    logout: async ({ commit, rootGetters }) => {
      const { confirmBox } = useElPlus();
      confirmBox("確認登出", "登出")
        .then(async () => {
          commit("clearUserVerify", "", { root: true });
          await router.push(rootGetters.loginUrl);
          reinitializeRouter();
        })
        .catch(() => {});
    },
    /* 重製密碼 */
    resetPassword: async (_, { new_password, old_password }) => {
      const { code } = await apiPostResetPassword({
        oldp: old_password,
        newp: new_password,
      });
      return code === 200;
    },
    /* 忘記密碼 */
    forgetPassword: async (
      _,
      { data: { email, code: verify_code, new_password: newp }, step }
    ) => {
      if (step === 1) {
        const { code } = await apiPostSendFPEmail({ email });
        return code === 200;
      } else {
        const { code } = await apiPostForgetPassword({
          email,
          code: verify_code,
          newp,
        });
        return code === 200;
      }
    },
    getApi: async ({ state, rootState }, cate) => {
      /* 如果有分角色則依照角色取得api */
      const api = state.api_list[cate];
      return rootState.role_id in api ? api[rootState.role_id] : api;
    },
    getList: async ({ dispatch, commit }, { cate, data }) => {
      // console.log(cate, data);
      const { type, getActionName, fun } = await dispatch("getApi", cate);
      const { code, data: res } = await apiGetList({
        type,
        getActionName,
        data,
      });
      if (data) data.res = res;
      else data = res;
      if (code === 200) commit(fun, data, { root: true });
    },
    /**執行post
     * @param {種類} cate
     * @param {要post出去的資料} data
     * @param {post完用來get的資料} getData
     * @param {post的action} action
     * @param {是否需要輸入密碼} checkPWD
     * @returns {成功:true, 失敗:false}
     */
    postAction: async (
      { dispatch },
      { cate, data, getData = {}, action = null, checkPWD = false }
    ) => {
      const { type, postActionName } = await dispatch("getApi", cate);
      if (action) data.action = action;
      if (checkPWD)
        await dispatch("checkPWD", { data }).then((res) => (data = res));
      const { code } = await apiPostAction({ type, postActionName, data });
      if (code === 200) {
        // console.log(cate);
        data = { ...data, ...getData };
        await dispatch("getList", { cate, data });
        return true;
      }
      return false;
    },
    /**
     * 刪除
     * @param cate 種類
     * @param data 資料
     * @param getData get用的資料
     * @param checkPWD 輸入密碼
     * @returns true false
     */
    deleteAction: (
      { dispatch },
      { cate, data, getData, checkPWD = false, confirmMsg }
    ) =>
      new Promise((res, rej) => {
        /* 執行post */
        const executeAction = async (type, postActionName) => {
          data.action = 3;
          const { code } = await apiPostAction({
            type,
            postActionName,
            data,
          });
          if (code === 200) {
            data = { ...data, ...getData };
            await dispatch("getList", { cate, data });
            return true;
          }
          return false;
        };
        dispatch("getApi", cate).then(
          async ({ type, name, postActionName }) => {
            if (checkPWD) {
              dispatch("checkPWD", { data }).then(async (pwdData) => {
                data = pwdData;
                await executeAction(type, postActionName);
                res();
              });
            } else {
              const { confirmBox } = useElPlus();
              confirmMsg = confirmMsg || `確認刪除${name}`;
              try {
                await confirmBox(confirmMsg, `刪除${name}`);
                const r = await executeAction(type, postActionName);
                if (r) {
                  res();
                } else {
                  rej();
                }
              } catch (error) {
                rej();
              }
            }
          }
        );
      }),
    /* 上傳文字編輯器圖片 */
    uploadEditorImg: async (_, { blobInfo, uploadProgress }) => {
      let formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      const res = await apiPostUploadEditorImg(formData, uploadProgress);
      return res;
    },
    /* 確認密碼彈窗 */
    checkPWD: (_, { data = {} }) =>
      new Promise((resolve) => {
        const { inputBox } = useElPlus();
        inputBox("請輸入您的密碼", {
          inputPlaceholder: "輸入密碼...",
          inputType: "password",
        })
          .then(({ value }) => {
            data.password = value;
            return resolve(data);
          })
          .catch(() => {});
      }),
    test: async () => {
      const data = {
        project_id: 1,
        action: 1,
        title: "123",
        description: "123",
        type: 2,
        option_values: ["123", "456"],
      };
      const res = await apiPostTest(data);
      console.error(res);
    },
  },
  getters: {},
  modules: {
    /* setting global */
    setting: {
      // namespaced: true,
      state: {
        educations: [],
        startEndDates: {},
      },
      mutations: {
        setStartEndDates: (state, data) => {
          state.startEndDates = data;
        },
        setEducations: (state, data) => {
          state.educations = data;
        },
      },
      actions: {
        getEducations: async ({ state, dispatch }) => {
          if (isNullOrEmpty(state.educations)) {
            await dispatch("getList", {
              cate: "Educations",
            });
          }
          return state.educations;
        },
        /* 過濾出有的education */
        educationFilter: async ({ state, dispatch }, educations) => {
          await dispatch("getEducations");
          // console.log("state.educations", state.educations);
          return state.educations.filter((item) => {
            return educations.includes(item.name);
          });
        },
        getStartEndDates: async (
          { state, rootState, commit },
          isSignup = false
        ) => {
          if (!isNullOrEmpty(state.startEndDates)) return state.startEndDates;

          //如果為報名頁面call的 呼叫6
          const type = isSignup || rootState.role_id === -1 ? 6 : 4;

          const { code, data = {} } = await apiGetList({
            type,
            getActionName: "get_sedate",
          });

          if (code === 200) commit("setStartEndDates", data);

          return data;
        },
      },
      getters: {},
    },
    project: {
      state: {
        years: null,
        // mapYears: null,
        projectInfos: {},
        curYearId: null,
        curYear: null,
        fields: {},
      },
      mutations: {
        setProjectYears: (state, years) => {
          state.years = JSON.parse(JSON.stringify(years));
          // let map = new Map();
          // for (let i = 0; i < years.length; i++) {
          //   const element = years[i];
          //   element.index = i;
          //   map.set(element.id, element);
          // }
          // state.mapYears = map;
        },
        setProjectInfo: (state, { data, project_id }) => {
          state.projectInfos[project_id] = data;
        },
        setCurYearId: (state, yearId) => {
          state.curYearId = yearId;
        },
        setCurYear: (state, year) => {
          state.curYear = year;
        },
        setFields: (state, { data, project_id }) => {
          state.fields[project_id] = data;
        },
      },
      actions: {
        yearsConstruct: async ({ dispatch }) => {
          await dispatch("getYears");
        },
        getYears: async ({ state, dispatch }) => {
          // if (!state.mapYears || !state.years)
          await dispatch("getList", { cate: "ProjectYears" });
          return state.years;
        },
        /**
         * 取得年度專題資訊
         */
        getProjectInfo: async (
          { getters, commit, state },
          { yearId = -1, year }
        ) => {
          //最新年分
          year = year || getters.getYearById(yearId);
          commit("setCurYearId", yearId);
          commit("setCurYear", year);
          //查找緩存
          const projectInfo = state.projectInfos[yearId];
          if (projectInfo) return projectInfo;
          const params = { id: yearId };
          const { data = [{}] } = await apiGetProjectInfo(params);
          commit("setProjectInfo", { data: data[0], project_id: yearId });
          return data[0] || {};
        },

        /**
         * 取得年度最新資訊
         */
        getProjectNews: async (_, id = null) => {
          const params = id ? { id } : null;
          const { data = [] } = await apiGetProjectNews(params);

          return data;
        },
        /**
         * 取得年度活動花絮
         */
        getProjectHighlights: async (_, id = null) => {
          const params = id ? { id } : null;
          const { data = [] } = await apiGetProjectHighlights(params);

          return data;
        },

        /**
         * ✔取得年度成果展示
         */
        getAchievements: async (_, { yearId, educationId, no }) => {
          const { data = [] } = await apiGetAchievements({
            id: yearId,
            education: educationId,
            no,
          });
          return data;
        },

        /**
         * 取得領域
         */
        getFields: async ({ state, commit }, id = null) => {
          const fields = state.fields[id];
          if (fields) return fields;
          const params = id && { id };
          const { data = [] } = await apiGetFields(params);
          commit("setFields", { data, project_id: id });
          return data;
        },
      },
      getters: {
        // mapYears: (state) => state.mapYears,
        mapYears: (state) => {
          const map = new Map();
          const years = state.years || [];
          for (let i = 0; i < years.length; i++) {
            const element = years[i];
            element.index = i;
            map.set(element.id, element);
          }
          return map;
        },
        first_year: (_, getters) => getters.mapYears.values().next().value.id,
        getYearById: (_, getters) => (id) => getters.mapYears.get(id)?.year,

        yearsMapByYear: (state) =>
          state.years?.mapBy((item) => item.year) || {},
        //透過年份取得id
        getYearIdByYear: (_, getters) => (year) =>
          getters.yearsMapByYear[year]?.id,

        getCurYear: (state) => state.curYear,
        getCurYearId: (state) => state.curYearId,
        getProjectInfo: (state) => {
          const curYearId = state.curYearId;
          return state.projectInfos[curYearId] || {};
        },
      },
    },
  },
};
