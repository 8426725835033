import { a_names } from "./Authorities";

export const names = {
  name: "名稱",
  account: "帳號",
  password: "密碼",
  email: "Email",
  phone: "電話號碼",
  code: "驗證碼",
  new_password: "新密碼",
  old_password: "舊密碼",
  check_password: "確認密碼",
  role_id: "身分",
  role_name: "身分",
  department_id: "系所",
  department_name: "系所",
  department: "系所",
  status: "狀態",
  ...a_names,
  action: "動作",
  dt: "時間",
  ip: "IP",
  education: "學制",
  education_id: "學制",
  /* 報名表 */
  class_id: "班級",
  class: "班級",
  teacher_id: "指導老師",
  fields_id: "專題領域",
  fields: "專題領域",
  field: "專題領域",
  project_name: "專題名稱",
  pname: "專題名稱",

  type: "類別",
  title: "標題",
  description: "描述",
  //專題設定
  year: "年份",
  enroll_time: "學生報名時間",
  enroll_start: "學生報名開始時間",
  enroll_end: "學生報名截止時間",
  upload_time: "資料上傳時間",
  upload_start: "資料上傳開始時間",
  upload_end: "資料上傳截止時間",
  wish_time: "老師志願選填",
  wish_start: "老師志願選填開始時間",
  wish_end: "老師志願選填截止時間",
  score_time: "評審評分時間",
  score_start: "評審評分開放時間",
  score_end: "評審評分截止時間",
  video: "影片",
  website: "網站",
  additional: "附加資訊",

  forday: "日夜",

  min: "最小值",
  max: "最大值",
  best: "最好的",

  join: "意願",
  no: "編號",
};
