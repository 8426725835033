import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache) {
  const _component_Loading = _resolveComponent("Loading");

  const _component_RouterView = _resolveComponent("RouterView");

  return _openBlock(), _createBlock(_component_RouterView, null, {
    default: _withCtx(({
      Component
    }) => [Component ? (_openBlock(), _createBlock(_Suspense, {
      key: 0,
      timeout: 0
    }, {
      fallback: _withCtx(() => [_createVNode(_component_Loading)]),
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2
    }, 1024)) : _createCommentVNode("", true)]),
    _: 1
  });
}