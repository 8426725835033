const check = {
  /* 電話號碼格式 */
  checkPhoneFormat: (value) => {
    const phone_format = /^09[0-9]{8}$/;
    // if (v) return false;
    return value && value.toString().match(phone_format);
  },
  /* email格式 */
  checkEmailFormat: (value) => {
    const mail_format = /^\w+([.-]?\w+)*@gmail.com$/;
    return value && value.match(mail_format);
  },
};
export const checkInput = {
  /* 檢查電話號碼 */
  checkPhone: (rule, value, callback) => {
    if (value && !check["checkPhoneFormat"](value))
      callback(new Error("電話號碼格式錯誤"));
    callback();
  },
  /* 檢查Email */
  checkEmail: (rule, value, callback) => {
    if (!check["checkEmailFormat"](value)) callback(new Error("Email格式錯誤"));
    callback();
  },
};
export const restrictInput = {
  /* 只能輸入數字 */
  onlyInputNumber: (value) => {
    // console.log("onlyInputNumber", value.replace(/[^0-9]/gi, ""));
    return value.replace(/[^0-9]/gi, "");
  },
};
