import {
  getList,
  getProjectNews,
  getProjectInfo,
  postAction,
  postForgetPassword,
  postLogin,
  postResetPassword,
  postSendFPEmail,
  postTest,
  postUploadEditorImg,
  getProjectHighlights,
  getFields,
  getAchievements,
} from "./module/GlobalApi";
import {
  postProjectSettingAction,
  postImportColumns,
  postImportFields,
  getRegistrationDetail,
  getScoreTeachers,
  getScoreTeachersOfTeams,
  postModifyScoreTeacher,
  getScoreTeamsOfTeachers,
  postDistributeTeachers,
  postManageReview,
  getReviewsOfTeams,
  getReviewsInfo,
  postManageReviewsOfTeams,
  getReviewList,
  getHighlights,
  postManageHighlights,
  postSetNo,
  postSendBackStudent,
  getScoreResults,
  postExportExcel,
  getGrades,
  postUploadAwards,
  postAnnounceAwards,
  postSendFieldsNotification,
  getFieldWishes,
  postSendScoreNotification,
  getRegistrationDetailFile,
  postSendNewPasswordMail,
} from "./module/ProjectApi";
import {
  getAuthorization,
  getRegistrationColumns,
  getRegistrationInfo,
  getRemark,
  postSignup,
  postStudentLogin,
  postUpdateRegistrationForm,
  postUpload,
  postVerifyEmail,
} from "./module/StudentApi";
import {
  getAllScoreTeams,
  getInstructingTeams,
  getScoreFields,
  getScoreForm,
  getScoreTeamDetail,
  getSignList,
  getTeamDetail,
  postChooseFields,
  postScore,
  postSign,
  postSubmitScore,
} from "./module/TeacherApi";

/* global */
export const apiPostLogin = postLogin;
export const apiPostResetPassword = postResetPassword;
export const apiPostSendFPEmail = postSendFPEmail;
export const apiPostForgetPassword = postForgetPassword;
export const apiGetList = getList;
export const apiPostAction = postAction;
export const apiPostTest = postTest;

export const apiPostUploadEditorImg = postUploadEditorImg;

export const apiGetProjectInfo = getProjectInfo;
export const apiGetProjectNews = getProjectNews;
export const apiGetProjectHighlights = getProjectHighlights;
export const apiGetFields = getFields;
export const apiGetAchievements = getAchievements;

//student
export const apiPostVerifyEmail = postVerifyEmail;
export const apiPostStudentLogin = postStudentLogin;
export const apiGetRegistrationColumns = getRegistrationColumns;
export const apiGetRegistrationInfo = getRegistrationInfo;
export const apiGetAuthorization = getAuthorization;
export const apiGetRemark = getRemark;
export const apiPostSignup = postSignup;
export const apiPostUpdateRegistrationForm = postUpdateRegistrationForm;
export const apiPostUpload = postUpload;
//executive
export const apiPostImportColumns = postImportColumns;
export const apiPostProjectSettingAction = postProjectSettingAction;
export const apiPostImportFields = postImportFields;
export const apiPostSendFieldsNotification = postSendFieldsNotification;
export const apiGetFieldWishes = getFieldWishes;

export const apiGetRegistrationDetail = getRegistrationDetail;
export const apiGetScoreTeachersOfTeams = getScoreTeachersOfTeams;
export const apiGetScoreTeamsOfTeachers = getScoreTeamsOfTeachers;
export const apiGetScoreTeachers = getScoreTeachers;
export const apiPostModifyScoreTeacher = postModifyScoreTeacher;
export const apiPostSendScoreNotification = postSendScoreNotification;

export const apiPostDistributeTeachers = postDistributeTeachers;
export const apiGetReviewsInfo = getReviewsInfo;
export const apiPostManageReview = postManageReview;
export const apiGetReviewsOfTeams = getReviewsOfTeams;
export const apiGetReviewList = getReviewList;
export const apiPostManageReviewsOfTeams = postManageReviewsOfTeams;
export const apiGetHighlights = getHighlights;
export const apiPostManageHighlights = postManageHighlights;
export const apiPostSendBackStudent = postSendBackStudent;
export const apiPostSetNo = postSetNo;
export const apiPostSendNewPasswordMail = postSendNewPasswordMail;
export const apiGetScoreResults = getScoreResults;
export const apiPostExportExcel = postExportExcel;
export const apiGetGrades = getGrades;
export const apiPostUploadAwards = postUploadAwards;
export const apiPostAnnounceAwards = postAnnounceAwards;
export const apiGetRegistrationDetailFile = getRegistrationDetailFile;

// teacher api
export const apiGetSignList = getSignList;
export const apiGetTeamDetail = getTeamDetail;
export const apiPostSign = postSign;
export const apiGetScoreFields = getScoreFields;
export const apiPostChooseFields = postChooseFields;
export const apiGetAllScoreTeams = getAllScoreTeams;
export const apiGetScoreTeamDetail = getScoreTeamDetail;
export const apiGetScoreForm = getScoreForm;
export const apiPostScore = postScore;
export const apiPostSubmitScore = postSubmitScore;
export const apiGetInstructingTeams = getInstructingTeams;
