import LoadingAnimation from "./LoadingAnimation.vue";
import InMask from "./Region/InMask.vue";
export default {
  name: "LoadingItem",
  components: {
    InMask,
    LoadingAnimation
  },

  setup() {// console.log('loading setup');
  }

};