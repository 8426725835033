import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "max-h-[90vh] overflow-y-auto"
};
import { toRefs } from "@vue/reactivity";
import { computed, onMounted, onUnmounted, ref } from "@vue/runtime-core";
export default {
  props: {
    position: {
      type: String,
      default: "absolute"
    },
    overflowHidden: {
      type: Boolean,
      default: false
    }
  },

  setup(__props) {
    const props = __props; // console.log(props);

    const {
      overflowHidden,
      position
    } = toRefs(props);
    /* 如果fixed並且設置不能滾動 則開啟 */

    const ov_hidden = computed(() => overflowHidden.value && position.value === "fixed");
    const originalClass = ref("");
    onMounted(() => {
      if (ov_hidden.value) {
        originalClass.value = document.body.classList.value;
        document.body.classList.add("overflow-hidden");
      }
    });
    onUnmounted(() => {
      if (ov_hidden.value) {
        document.body.className = originalClass.value;
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_unref(position), 'w-full h-full px-2 flex items-center justify-center bg-gray-700 bg-opacity-60 top-0 left-0 z-50'])
      }, [_createElementVNode("section", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 2);
    };
  }

};