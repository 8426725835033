import { createStore } from "vuex";
import global from "./modules/global";
import input from "./modules/input";
import router from "@/router";
import { not_found_route } from "@/router/modules/404_route";
import useElPlus from "@/compositions/ElPlus";
import { student_routes } from "@/router/modules/student/student_routes";
import { ROUTE_TITLES } from "@/router/RouteNames";
let all_routes = "";
/* 匯入所有routes */
const importRoutes = async () => {
  const { routes } = require("@/router/all_routes");
  all_routes = routes;
};
export const store = createStore({
  state: {
    user: "",
    verify: "",
    user_authorities: [],
    role_id: 0,
    isLogin: false,
    status: "",
    menu: [],
    extraMenu: [],
    //roleId跟apiType的對應關係
    roleIdReflectToApiType: {
      1: 2,
      2: 3,
      3: 3,
      4: 3,
      5: 4,
      6: 5,
    },
  },
  mutations: {
    /* 狀態 */
    setStatus: (state, { code, msg }) => {
      const { message } = useElPlus();
      if (code === 200) {
        if (msg) message(msg);

        state.status = "success";
      } else if (code === 400 || code === 401) {
        message(msg, "error");
        state.status = "error";
      }
    },
    /* 清除狀態 */
    clearStatus: (state) => {
      state.status = "";
    },
    setUserVerify: (state, { authorities, role_id, verify, user }) => {
      state.user = user;
      state.user_authorities = authorities;
      state.role_id = role_id;
      state.verify = verify;
      state.isLogin = true;
      // console.log("setUserVerify :>> ", { authorities, role_id, verify, user });
      sessionStorage.setItem("user", user);
      sessionStorage.setItem("verify", verify);
      sessionStorage.setItem("role_id", role_id);
      sessionStorage.setItem("user_authorities", JSON.stringify(authorities));
    },
    clearUserVerify: (state) => {
      // state.user = "";
      // state.user_authorities = "";
      // state.verify = "";
      state.isLogin = false;
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("verify");
      sessionStorage.removeItem("role_id");
      sessionStorage.removeItem("user_authorities");
    },
    setMenu: (state, { menu, extraMenu }) => {
      state.menu = menu;
      state.extraMenu = extraMenu;
    },
  },
  actions: {
    getUser: async ({ dispatch, commit }) => {
      const user = sessionStorage.getItem("user") || "";
      const authorities = sessionStorage.getItem("user_authorities") || "{}";
      const verify = sessionStorage.getItem("verify") || "";
      const role_id = parseInt(sessionStorage.getItem("role_id")) || null;
      //同時擁有verify, user, role_id 才視為已登入
      if (verify && user && role_id) {
        dispatch("updateUserStatus", {
          user,
          authorities: JSON.parse(authorities),
          verify,
          role_id,
        });
      } else {
        //否則清除sessionStorage
        commit("clearUserVerify");
      }
    },
    /* 更新使用者狀態 */
    updateUserStatus: async (
      { commit, dispatch },
      { authorities = {}, user = "", verify, role_id = null }
    ) => {
      commit("setUserVerify", { authorities, role_id, verify, user });
      await dispatch("addRoute");
    },
    /* 添加路由 */
    addRoute: async ({ state, dispatch, commit }) => {
      if (state.isLogin) {
        //先移除404
        router.removeRoute("NotFound");

        const role_id = sessionStorage.getItem("role_id");
        /* 依照權限過濾出路由、menu */
        const { extraMenu, menu } =
          role_id == -1
            ? await dispatch("addStudentRoutes")
            : await dispatch("routeFilter");

        // console.log("{ menu, extraMenu } :>> ", { menu, extraMenu });
        commit("setMenu", { menu, extraMenu });

        router.addRoute(not_found_route);
      }
    },
    routeFilter: async ({ state, getters, dispatch }) => {
      const { user_authorities } = state;
      if (!all_routes) await importRoutes();
      const role = getters.role;
      /* 新增global_routes */
      const {
        home_route: originalHomeRoute,
        importHomeComponent,
        homePath,
      } = require("@/router/modules/bcms/home_route");
      const homeRoute = JSON.parse(JSON.stringify(originalHomeRoute));
      homeRoute.component = importHomeComponent;
      const menu = [];
      for (const key of Object.keys(all_routes)) {
        if (user_authorities[key]) {
          // console.log("key", key, "auth", all_routes[key]);
          all_routes[key].forEach(async (route) => {
            // router.
            // router.addRoute(route);
            homeRoute.children.push(route);
            // console.log("route :>> ", route);
            menu.push(await dispatch("getMenuItem", { route, homePath, role }));
          });

          // if (typeof menu[key] === "object") filteredMenu.push(menu[key]);
          // else menu[key].forEach((menu) => filteredMenu.push(menu));
        }
      }
      router.addRoute(homeRoute);
      const extraMenu = ["resetPassword", "logout"];
      return { menu, extraMenu };
    },
    /**
     * 新增學生路由, menu
     */
    addStudentRoutes: async ({ getters, dispatch }) => {
      const menu = [];
      const role = getters.role;
      const {
        home_route,
        homePath,
      } = require("@/router/modules/bcms/home_route");
      student_routes.forEach(async (route) => {
        home_route.children.push(route);
        // router.addRoute(route);
        menu.push(await dispatch("getMenuItem", { route, homePath, role }));
      });
      router.addRoute(home_route);
      const extraMenu = ["logout"];
      return { menu, extraMenu };
    },

    getMenuItem: (_, { route, homePath, role }) => {
      const routeName = route.name || route.children[0].name;
      return {
        name: route.meta.header_title || ROUTE_TITLES[routeName],
        svg: route.meta.svg,
        route_name: routeName,
        path: `${homePath.replace("${role}", role)}/${route.path}`,
      };
    },
  },
  getters: {
    role: (state) => {
      const role_id = state.role_id;
      switch (role_id) {
        case -1:
          return "student";
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return "teacher";
        case 6:
          return "review";
        default:
          return null;
      }
    },
    loginUrl: (_, getters) => {
      return `/${getters.role}/login`;
    },
  },
  modules: {
    input,
    global,
  },
});
export const registerModule = (name, module) => {
  if (!store.state[name]) store.registerModule(name, module);
};

export const unRegisterModule = (name) => {
  if (store.hasModule(name)) store.unregisterModule(name);
};
