import { ROUTE_NAMES } from "@/router/RouteNames";

/* header */
const header = () => import("@/components/Container/HeaderContainer");

export const project_routes = {
  /* 專題資訊管理 */
  manage_projects: [
    {
      path: "projects",
      name: ROUTE_NAMES.bcms.project.projectSetting,
      meta: {
        svg: "info",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/ProjectSettings"),
      },
    },
  ],
  /* 最新消息管理 */
  manage_news: [
    {
      path: "news",
      name: ROUTE_NAMES.bcms.project.news,
      meta: {
        svg: "newspaper",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/News"),
      },
    },
  ],
  /* 設定報名表 */
  set_form: [
    {
      path: "registrations",
      name: ROUTE_NAMES.bcms.project.registrationSetting,
      meta: {
        svg: "file-pen",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/RegistrationSettings"),
      },
    },
  ],
  /* 設定評分表 */
  set_score: [
    {
      path: "scores",
      name: ROUTE_NAMES.bcms.project.scoreSetting,
      meta: {
        svg: "file-pen",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/ScoreSettings"),
      },
    },
  ],
  /* 設定評分老師 */
  set_score_teacher: [
    {
      path: "score-teachers",
      name: ROUTE_NAMES.bcms.project.scoreTeachers,
      meta: {
        svg: "user-setting",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/ScoreTeachers"),
      },
    },
  ],
  /* 取得評分結果 */
  get_score_status: [
    {
      path: "score-results",
      name: ROUTE_NAMES.bcms.project.scoreResults,
      meta: {
        svg: "clipboard-list",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/ScoreResults"),
      },
    },
  ],
  /* 評審管理 */
  manage_review: [
    {
      path: "reviews",
      name: ROUTE_NAMES.bcms.project.reviews,
      meta: {
        svg: "user-tie",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/Reviews"),
      },
    },
  ],
  /* 活動花絮 */
  manage_event_pics: [
    {
      path: "highlights",
      name: ROUTE_NAMES.bcms.project.highlights,
      meta: {
        svg: "images",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/Highlights"),
      },
    },
  ],
  /* 檢視報名狀況 */
  view_signup_status: [
    {
      path: "registration-details",
      meta: {
        svg: "calender-check",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/RegistrationDetails"),
      },
      redirect: { name: ROUTE_NAMES.bcms.project.registrationDetails.index },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.bcms.project.registrationDetails.index,
          component: () =>
            import("@/views/bcms/project/RegistrationDetails/subpages/list"),
        },
        {
          path: ":id",
          name: ROUTE_NAMES.bcms.project.registrationDetails.id,
          component: () =>
            import("@/views/bcms/project/RegistrationDetails/subpages/id"),
        },
      ],
    },
  ],
  /* 領域管理 */
  manage_fields: [
    {
      path: "fields",
      name: ROUTE_NAMES.bcms.project.fields,
      meta: {
        svg: "tags",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/Fields"),
      },
    },
  ],
  set_awards: [
    {
      path: "awards",
      name: ROUTE_NAMES.bcms.project.awards,
      meta: {
        svg: "chart-vertical",
      },
      components: {
        header,
        default: () => import("@/views/bcms/project/Awards"),
      },
    },
  ],
};
