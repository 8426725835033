import { ROUTE_NAMES } from "@/router/RouteNames";

export const importHomeComponent = () => import("@/views/bcms");

export const homePath = "/${role}";
export const home_route =
  /* 首頁 */
  {
    path: "/teacher",
    alias: ["/review", "/student"],
    name: ROUTE_NAMES.bcms.home,
    component: importHomeComponent,
    children: [],
  };
