import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AsyncRouterView = _resolveComponent("AsyncRouterView");

  const _component_ElBacktop = _resolveComponent("ElBacktop");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_AsyncRouterView), _createVNode(_component_ElBacktop, {
    right: 25,
    bottom: 25,
    class: "!text-blue-500"
  })], 64);
}