import { computed, h, toRefs } from "@vue/runtime-core";
import { svg } from "@/compositions/SVG";
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    inline: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: ""
    },
    pathClass: {
      type: String,
      default: ""
    },
    title: {
      type: String
    }
  },

  setup(props) {
    //props
    const {
      type,
      inline,
      size,
      pathClass,
      title
    } = toRefs(props); // console.log(svg);

    const getSizeClass = computed(() => size.value ? `fa-${size.value}` : "");
    return () => h("svg", {
      class: `${inline.value ? "svg-inline--fa" : ""} ${getSizeClass.value}`,
      viewBox: svg[type.value].viewBox,
      xmlns: svg[type.value].xmlns
    }, [h("path", {
      class: pathClass.value,
      d: svg[type.value].path.d,
      "full-rule": svg[type.value].path["fill-rule"] ? svg[type.value].path["fill-rule"] : "",
      fill: "currentColor"
    }), title.value ? h("title", title.value) : null]);
  }

};