import { post } from "../axiosConfig";

export const postLogin = (data) => post("1/login", data);
export const postResetPassword = (data) => post("1/reset_password", data);
export const postSendFPEmail = (data) => post("1/forget", data);
export const postForgetPassword = (data) => post("1/code_set_password", data);

export const getList = ({ type, getActionName, data }) =>
  post(`${type}/${getActionName}`, data);
export const postAction = ({ type, postActionName, data }) =>
  post(`${type}/${postActionName}`, data);

export const postTest = (data) => post("3/set_form", data);

export const postUploadEditorImg = (data, uploadProgress) =>
  post("3/upload", data, uploadProgress);

/**
 * ✔取得年度專題資訊
 * @param {{id:Number?}} data 不填id則回傳最新年度資訊
 * @returns
 */
export const getProjectInfo = (data) => post("1/get_projects", data);

/**
 * ✔取得年度最新資訊
 * @param {{id:Number?}} data 不填id則回傳最新年度資訊
 * @returns
 */
export const getProjectNews = (data) => post("1/get_news", data);

/**
 * ✔取得年度活動花絮
 * @param {{id:Number?}} data id(不填id則回傳最新年度資訊)
 * @returns
 */
export const getProjectHighlights = (data) => post("1/get_event_pics", data);

/**
 * ✔取得年度成果展示
 * @param {{id:Number, education:Number}} data
 * @returns
 */
export const getAchievements = (data) => post("1/get_achievement", data);
/**
 * 取得領域清單
 * @param {{id:Number}} data id(不填id則回傳最新年度資訊)
 */
export const getFields = (data) => post("1/get_fields", data);
