import { ElButton, ElTableColumn } from "element-plus/es";
// import ElementPlus from "element-plus";
// import locale from "element-plus/lib/locale/lang/zh-tw";
// import lang from "element-plus/lib/locale/lang/zh-tw";

const components = [ElButton, ElTableColumn];
export default (app) => {
  //   console.log(app);
  // app.use(ElementPlus, { locale });
  //   locale(lang);
  components.forEach((component) => app.component(component.name, component));
};
