import { ElMessage, ElMessageBox, ElNotification } from "element-plus/lib";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";
import "element-plus/theme-chalk/el-notification.css";

export default function useElPlus() {
  /**
   * 顯示訊息
   * @param {String} msg
   * @param {String} type
   */
  const message = (msg, type = "success") => {
    ElMessage[type](msg);
  };
  /**
   * 彈出確認框
   * @param {String} msg
   * @param {String} title
   * @param {object} options
   * @param {String?} options.confirmButtonText
   * @param {String?} options.cancelButtonText
   * @param {String?} options.type
   * @returns
   */
  const confirmBox = (msg, title, options = {}) => {
    const {
      confirmButtonText = "確定",
      cancelButtonText = "取消",
      type = "warning",
      ...rest
    } = options;
    return ElMessageBox.confirm(msg, title, {
      type,
      confirmButtonText,
      cancelButtonText,
      ...rest,
    });
  };
  /**
   *
   * @param {String} msg
   * @param {{confirmButtonText:String, cancelButtonText:String, inputPlaceholder:String, inputValidator:Function}} options
   * @returns
   */
  const inputBox = (msg, options = {}) => {
    const {
      confirmButtonText = "確定",
      cancelButtonText = "取消",
      inputPlaceholder = "輸入文字...",
      inputValidator = (val) => {
        if (!val) return "請輸入欄位";
      },
      ...rest
    } = options;
    return ElMessageBox.prompt(msg, {
      confirmButtonText,
      cancelButtonText,
      inputPlaceholder,
      inputValidator,
      ...rest,
    });
  };
  /**
   * 跳出通知
   * @param {String} msg 顯示訊息
   * @param {String} title 標題
   * @param {{type:String, automaticallyClose:Boolean, position:String, offset:Number}} config
   * @param {String} config.type success, warning, info and error 預設不指定type
   * @param {Boolean} config.automaticallyClose 自動關閉，預設為true
   * @param {String} config.position bottom-right, bottom-left and top-left 顯示位置，預設為右上
   * @param {Number} config.offset 偏移量 預設為0
   */
  const notification = (
    msg,
    title,
    { type = "", automaticallyClose = true, position = "", offset = 0 } = {
      type: "",
      automaticallyClose: true,
      position: "",
      offset: 0,
    }
  ) => {
    const config = {
      title: title,
      message: msg,
    };
    !automaticallyClose && (config.duration = 0);
    type && (config.type = type);
    position && (config.position = position);
    offset && (config.offset = offset);
    ElNotification(config);
  };
  return { message, confirmBox, inputBox, notification };
}
