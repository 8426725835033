import { ROUTE_NAMES } from "@/router/RouteNames";

/* header */
const header = () => import("@/components/Container/HeaderContainer");

export const setting_routes = {
  /* 系所管理、權限管理、行政帳號管理、log */
  college: [
    {
      path: "departments",
      meta: {
        svg: "department",
      },
      components: {
        header,
        default: () => import("@/views/bcms/setting/Departments"),
      },
      redirect: { name: ROUTE_NAMES.bcms.setting.departments.index },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.bcms.setting.departments.index,
          component: () => import("@/views/bcms/setting/Departments/subpages"),
        },
        {
          path: "create",
          name: ROUTE_NAMES.bcms.setting.departments.create,
          meta: {
            action: 1,
          },
          component: () =>
            import("@/views/bcms/setting/Departments/subpages/action"),
        },
        {
          path: "update",
          meta: {
            action: 2,
          },
          name: ROUTE_NAMES.bcms.setting.departments.update,
          component: () =>
            import("@/views/bcms/setting/Departments/subpages/action"),
        },
      ],
    },
    {
      path: "authorities",
      name: ROUTE_NAMES.bcms.setting.authorities,
      meta: {
        svg: "permission",
      },
      components: {
        header,
        default: () => import("@/views/bcms/setting/Authorities"),
      },
    },
    {
      path: "executives",
      meta: {
        svg: "executive",
      },
      redirect: { name: ROUTE_NAMES.bcms.setting.executives.index },
      components: {
        header,
        default: () => import("@/views/bcms/setting/Executives"),
      },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.bcms.setting.executives.index,
          component: () => import("@/views/bcms/setting/Executives/subpages"),
        },
        {
          path: "create",
          name: ROUTE_NAMES.bcms.setting.executives.create,
          meta: {
            action: 1,
          },
          component: () =>
            import("@/views/bcms/setting/Executives/subpages/action"),
        },
        {
          path: "update",
          meta: {
            action: 2,
          },
          name: ROUTE_NAMES.bcms.setting.executives.update,
          component: () =>
            import("@/views/bcms/setting/Executives/subpages/action"),
        },
      ],
    },
    {
      path: "log",
      name: ROUTE_NAMES.bcms.setting.log,
      meta: {
        svg: "log",
      },
      components: {
        header,
        default: () => import("@/views/bcms/setting/Logs"),
      },
    },
  ],
  /* 老師帳號管理 */
  manage_teacher: [
    {
      path: "teachers",
      meta: {
        svg: "user-group",
      },
      redirect: { name: ROUTE_NAMES.bcms.setting.teachers.index },
      components: {
        header,
        default: () => import("@/views/bcms/setting/Teachers"),
      },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.bcms.setting.teachers.index,
          component: () => import("@/views/bcms/setting/Teachers/subpages"),
        },
        {
          path: "create",
          name: ROUTE_NAMES.bcms.setting.teachers.create,
          meta: {
            action: 1,
          },
          component: () =>
            import("@/views/bcms/setting/Teachers/subpages/action"),
        },
        {
          path: "update",
          meta: {
            action: 2,
          },
          name: ROUTE_NAMES.bcms.setting.teachers.update,
          component: () =>
            import("@/views/bcms/setting/Teachers/subpages/action"),
        },
      ],
    },
  ],
  /* 班級管理 */
  manage_classes: [
    {
      path: "classes",
      name: ROUTE_NAMES.bcms.setting.classes,
      meta: {
        svg: "address-book",
      },
      components: {
        header,
        default: () => import("@/views/bcms/setting/Classes"),
      },
    },
  ],
  // /* 權限管理 */
  // edit_authorities: [],
  // /* 行政帳號管理 */
  // manage_users: [],
  // /* log */
  // get_activity_record: [],
};
