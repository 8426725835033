import { checkInput } from "@/compositions/Input/CheckInput";

export default {
  namespaced: true,
  state: {
    // basic_input_fields: {
    //   student_name: {
    //     prop: "student_name",
    //     placeholder: "輸入%TEXT%姓名",
    //     label: "%TEXT%姓名",
    //   },
    //   student_number: {
    //     prop: "student_number",
    //     placeholder: "輸入%TEXT%學號 ex:1110734020",
    //     label: "%TEXT%學號",
    //     valChangeFun: checkFormat["onlyInputNumber"],
    //   },
    //   student_phone: {
    //     prop: "student_phone",
    //     placeholder: "輸入%TEXT%電話",
    //     label: "%TEXT%電話",
    //     valChangeFun: checkFormat["onlyInputNumber"],
    //   },
    //   student_email: {
    //     prop: "student_email",
    //     placeholder: "輸入%TEXT%Email",
    //     label: "%TEXT%Email",
    //   },
    // },
  },
  mutations: {},
  actions: {},
  getters: {
    // /* 取得Input欄位 */
    // getInputField: (state) => (type, text) => {
    //   /* 深拷貝物件 */
    //   let input_field = JSON.parse(
    //     JSON.stringify(state.basic_input_fields[type])
    //   );
    //   // console.log("----------------------------------------------", type, text);
    //   /* 循環key 將%TEXT%替換為變數 */
    //   if (text) {
    //     for (const key in input_field)
    //       if (typeof input_field[key] === "string") {
    //         input_field[key] = input_field[key].replace("%TEXT%", text);
    //       }
    //   }
    //   return input_field;
    // },
    /* 產生input fields陣列 */
    generateIFs:
      () =>
      (source, merge, ...columns) => {
        return columns.reduce((acc, column) => {
          if (merge) {
            const component = `${source[column].component || "text"}_fs`;
            acc[component] = acc[component] || {};
            acc[component][column] = source[column];
          } else acc[column] = source[column];
          // console.log(source[column], columns);
          return acc;
        }, {});
      },
    /**
     * @param props 欄位
     * @param required 必要 預設為true
     * @param max 最大值
     * @param validators 驗證器
     * @param trigger 觸發時機
     * @param tips 提示文字
     * @returns {key:[{rule1},{rule2}]}
     */
    generateRules:
      () =>
      ({
        props = [],
        required = {},
        max = {},
        min = {},
        validators = {},
        trigger = {},
        tips = {},
      }) => {
        const { names } = require("@/compositions/names");
        return props.reduce((acc, prop) => {
          const tip_text = tips[prop] || names[prop];
          let arr = [];
          if (required[prop] !== false)
            arr.push(
              trigger[prop] && trigger[prop]["required"]
                ? {
                    required: true,
                    message: `${tip_text}為必填欄位`,
                    trigger: trigger[prop]["required"],
                  }
                : { required: true, message: `${tip_text}為必填欄位` }
            );
          if (max[prop])
            arr.push(
              trigger[prop] && trigger[prop]["max"]
                ? {
                    max: max[prop],
                    message: `${tip_text}最多為${max[prop]}字元`,
                    trigger: trigger[prop]["max"],
                  }
                : {
                    max: max[prop],
                    message: `${tip_text}最多為${max[prop]}字元`,
                  }
            );
          if (min[prop])
            arr.push(
              trigger[prop]?.["min"]
                ? {
                    min: min[prop],
                    message: `${tip_text}最少為${min[prop]}字元`,
                    trigger: trigger[prop]["min"],
                  }
                : {
                    min: min[prop],
                    message: `${tip_text}最少為${min[prop]}字元`,
                  }
            );
          if (validators[prop])
            arr.push({ validator: checkInput[validators[prop]] });

          acc[prop] = arr;
          return acc;
        }, {});
      },
  },
  modules: {},
};
