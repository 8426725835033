export const ROUTE_NAMES = {
  signup: "signup",
  login: "login",
  forgetPassword: "forget-password",
  notFound: "NotFound",
  client: {
    home: "client-home",
    news: "client-news",
    procedures: "client-procedures",
    awards: "client-awards",
    achievements: "client-achievements",
    achievementsDetail: "client-achievements-detail",
    highlights: "client-highlights",
  },
  student: {
    registration: "student-registration",
    information: "student-information",
  },
  bcms: {
    home: "dashboard",
    setting: {
      departments: {
        index: "departments",
        create: "departments-create",
        update: "departments-update",
      },
      authorities: "authorities",
      executives: {
        index: "executives",
        create: "executives-create",
        update: "executives-update",
      },
      log: "log",
      teachers: {
        index: "teachers",
        create: "teachers-create",
        update: "teachers-update",
      },
      classes: "classes",
    },
    project: {
      projectSetting: "project-setting",
      news: "news",
      registrationSetting: "registration-setting",
      scoreSetting: "score-setting",
      scoreTeachers: "score-teachers",
      scoreResults: "score-results",
      reviews: "reviews",
      highlights: "highlights",
      registrationDetails: {
        index: "registration-details",
        id: "registration-details-id",
      },
      fields: "fields",
      awards: "awards",
    },
    teacher: {
      registrationSign: {
        index: "registration-sign",
        id: "registration-sign-id",
      },
      scoreFields: "score-fields",
      scoreDetails: {
        index: "score-details",
        id: "score-details-id",
      },
      teams: {
        index: "teams",
        id: "teams-id",
      },
    },
  },
};

function flatRouteTitles(routeNames, name) {
  return Object.values(routeNames).reduce((prev, routeName) => {
    prev[routeName] = name;
    return prev;
  }, {});
}

export const ROUTE_TITLES = {
  [ROUTE_NAMES.signup]: "報名",
  [ROUTE_NAMES.login]: "登入",
  [ROUTE_NAMES.forgetPassword]: "忘記密碼",
  [ROUTE_NAMES.notFound]: "查無頁面",
  //client
  [ROUTE_NAMES.client.news]: "最新消息",
  [ROUTE_NAMES.client.procedures]: "專題流程",
  [ROUTE_NAMES.client.awards]: "獲獎名單",
  [ROUTE_NAMES.client.achievements]: "成果展示",
  [ROUTE_NAMES.client.achievementsDetail]: "成果展示",
  [ROUTE_NAMES.client.highlights]: "活動花絮",
  //student
  [ROUTE_NAMES.student.registration]: "報名表資訊",
  [ROUTE_NAMES.student.information]: "專題資料",
  //bcms
  [ROUTE_NAMES.bcms.home]: "首頁",
  //bcms-setting
  ...flatRouteTitles(ROUTE_NAMES.bcms.setting.departments, "系所管理"),
  [ROUTE_NAMES.bcms.setting.authorities]: "權限管理",
  ...flatRouteTitles(ROUTE_NAMES.bcms.setting.executives, "行政帳號管理"),
  [ROUTE_NAMES.bcms.setting.log]: "活動紀錄",
  ...flatRouteTitles(ROUTE_NAMES.bcms.setting.teachers, "老師帳號管理"),
  [ROUTE_NAMES.bcms.setting.classes]: "班級管理",
  [ROUTE_NAMES.bcms.project.projectSetting]: "專題資訊管理",
  [ROUTE_NAMES.bcms.project.news]: "最新消息管理",
  [ROUTE_NAMES.bcms.project.registrationSetting]: "設定報名表",
  [ROUTE_NAMES.bcms.project.scoreSetting]: "設定評分表",
  [ROUTE_NAMES.bcms.project.scoreTeachers]: "設定系統評分",
  [ROUTE_NAMES.bcms.project.scoreResults]: "取得評分結果",
  [ROUTE_NAMES.bcms.project.reviews]: "設定簡報評審",
  [ROUTE_NAMES.bcms.project.highlights]: "活動花絮管理",
  ...flatRouteTitles(
    ROUTE_NAMES.bcms.project.registrationDetails,
    "檢視報名狀況"
  ),
  [ROUTE_NAMES.bcms.project.fields]: "領域管理",
  [ROUTE_NAMES.bcms.project.awards]: "成績結算",
  ...flatRouteTitles(ROUTE_NAMES.bcms.teacher.registrationSign, "簽核報名表"),
  [ROUTE_NAMES.bcms.teacher.scoreFields]: "領域選填",
  ...flatRouteTitles(ROUTE_NAMES.bcms.teacher.scoreDetails, "評分"),
  ...flatRouteTitles(ROUTE_NAMES.bcms.teacher.teams, "指導組別得分"),
};

/**
 * menu順序
 */
export const MENU_ORDER = [
  ROUTE_NAMES.bcms.setting.authorities,
  ROUTE_NAMES.bcms.setting.departments.index,
  ROUTE_NAMES.bcms.setting.executives.index,
  ROUTE_NAMES.bcms.project.projectSetting,
  ROUTE_NAMES.bcms.project.news,
  ROUTE_NAMES.bcms.project.registrationSetting,
  ROUTE_NAMES.bcms.project.registrationDetails.index,
  ROUTE_NAMES.bcms.project.scoreSetting,
  ROUTE_NAMES.bcms.project.scoreTeachers,
  ROUTE_NAMES.bcms.project.scoreResults,
  ROUTE_NAMES.bcms.project.reviews,
  ROUTE_NAMES.bcms.project.awards,
  ROUTE_NAMES.bcms.project.fields,
  ROUTE_NAMES.bcms.setting.teachers.index,
  ROUTE_NAMES.bcms.project.highlights,
  ROUTE_NAMES.bcms.setting.classes,

  ROUTE_NAMES.bcms.teacher.registrationSign.index,
  ROUTE_NAMES.bcms.teacher.scoreFields,
  ROUTE_NAMES.bcms.teacher.scoreDetails.index,
  ROUTE_NAMES.bcms.teacher.teams.index,

  ROUTE_NAMES.bcms.setting.log,
];
