import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import "@/Array";
import "@/Map";
import AsyncRouterView from "./components/Container/AsyncRouterView.vue";
export default {
  name: "App",
  components: {
    AsyncRouterView
  },

  setup() {
    const {
      dispatch
    } = useStore(); // console.log("defaultActive :>> ", defaultActive, route.path);

    onMounted(async () => {
      await dispatch("getUser"); // First we get the viewport height and we multiple it by 1% to get a value for a vh unit

      const vh = window.innerHeight / 100; // Then we set the value in the --vh custom property to the root of the document

      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }

};