import axios from "axios";
import Qs from "qs";
import { store } from "@/store";
import useElPlus from "@/compositions/ElPlus";
/* 引入進度條 */
import NProgress from "nprogress";
/* 引入進度條樣式 */
import "nprogress/nprogress.css";
axios.defaults.baseURL = `${process.env.VUE_APP_API_SERVER}/api/`;
//設置請求超時
axios.defaults.timeout = 30000;
//攔截request
axios.interceptors.request.use(
  (config) => {
    /* 進度條開始 */
    NProgress.start();
    // console.log(`${config.url}: request->`, config);
    const verify = sessionStorage.getItem("verify");
    //如果是form
    if (Object.prototype.toString.call(config.data) === "[object FormData]") {
      config.data.append("verify", verify);
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.data = config.data || {};
      config.data.verify = config.data.verify || verify;
      config.data = Qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 攔截response
axios.interceptors.response.use(
  (response) => {
    /* 進度條結束 */
    NProgress.done();
    const { data } = response;
    if (process.env.NODE_ENV !== "production")
      console.log(`${response.config.url}: response->`, response);
    // console.log("data", data);
    const { code, msg } = data;
    store.commit("setStatus", { code, msg });
    /* 回傳裡面的data */
    return data;
  },
  (error) => {
    const { response } = error;
    if (!response || response.status === 404 || response.status === 500) {
      if (!response) {
        console.error(`404 error ${error}`);
      } else {
        console.error(`${response.status} error =>${error}`);
        // throw new store.commit("setStatus", {
        //   msg: "伺服器異常，請稍後再試",
        // });
      }
      const { message } = useElPlus();
      message("伺服器異常，請稍後再試", "error");
    }
    return Promise.reject(error.message);
  }
);

export const post = (url, data, uploadProgress) => {
  return axios.post(url + ".php", data, { onUploadProgress: uploadProgress });
};
export const get = async (url) => {
  return axios.get(url + ".php");
};
