import { post } from "../axiosConfig";

// 發送報名驗證信, 驗證驗證碼
export const postVerifyEmail = (data) => post("6/verify", data);

export const postStudentLogin = (data) => post("6/login", data);
export const getRegistrationColumns = () => post("6/get_columns");
export const getAuthorization = () => post("6/get_authorize");
export const getRemark = () => post("6/get_remark");
//報名
export const postSignup = (data) => post("6/enroll", data);
export const getRegistrationInfo = (data) => post("6/get_info", data);
export const postUpdateRegistrationForm = (data) => post("6/edit", data);

//上傳檔案
export const postUpload = (data) => post("6/upload", data);
